const locale = {
	location: 'Locatie en Ligging',
	characteristics: 'Woningkenmerken',
	sustainability: 'Duurzaamheid',
	quality: 'Kwaliteit en Onderhoud',
	sale: 'Verkoop',
	worth: 'WOZ-waarde',
	neighborhood: 'Buurt',
	type: 'Type woning',
	yearBuilt: 'Bouwjaar',
	buildingSize: 'Oppervlakte',
	landSize: 'Perceeloppervlakte',
	annex: 'Aanbouw woonruimte', // TODO: Subheaders
	detachedStorage: 'Vrijstaande berging',
	annexStorage: 'Aangebouwde berging',
	awning: 'Luifel of overkapping',
	garage: 'Garage',
	dormers: 'Dakkapel',
	energyLabel: 'Energielabel',
	solarPanels: 'Zonnepanelen',
	maintenanceRating: 'Onderhoudstoestand',
	qualityRating: 'Kwaliteit',
	serviceRating: 'Voorzieningen in woning',
	price: 'Verkoopprijs',
	date: 'Verkoopdatum',
	value: 'WOZ-waarde',
};

export default (key) => locale[key];
