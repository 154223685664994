import React from 'react';
import styled from 'styled-components';

import BalloonGraphic from '../graphics/graphic-balloon.svg';

const StyledValueGraph = styled.svg`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: visible;

	* {
		overflow: visible;
	}

	.container {
		stroke: #0057ff;
		fill: none;
		stroke-width: 2px;
	}

	.gridlines {
		stroke-width: 0.5px;
		stroke-dasharray: 3px 3px;
		stroke: #0057ff;
		fill: none;
	}

	.graph-fills {
		stroke: none;

		path:nth-child(1) {
			fill: #ffddfd;
		}

		path:nth-child(2) {
			fill: #c2d7ff;
		}

		path:nth-child(3) {
			fill: #c2e9e0;
		}

		path:nth-child(4) {
			fill: #fce5c2;
		}

		* {
			vector-effect: non-scaling-stroke;
		}
	}

	.vertices {
		& > g:nth-child(1) circle {
			stroke: #ff71f8;
			fill: #e6ffff;
			stroke-width: 2px;
		}

		& > g:nth-child(2) circle {
			stroke: #0057ff;
			fill: #e6ffff;
			stroke-width: 2px;
		}

		& > g:nth-child(3) circle {
			stroke: #00a37e;
			fill: #e6ffff;
			stroke-width: 2px;
		}

		& > g:nth-child(4) circle {
			stroke: #f29100;
			fill: #e6ffff;
			stroke-width: 2px;
		}

		/* & > g svg:hover g {
			display: block;
		} */

		/* svg g {
			display: none;
		} */

		svg g rect {
			stroke: #0057ff;
			stroke-width: 2px;
			fill: #e6ffff;
			/* fill: url(#gradient); */
		}

		svg g path {
			stroke: #0057ff;
			stroke-width: 2px;
			fill: #e6ffff;
			/* fill: #0057ff; */
		}

		svg g circle {
			stroke: none !important;
			fill: #0057ff !important;
		}

		svg g text {
			fill: #0057ff;
			font-size: 15px;
			text-anchor: middle;

			&.value {
				font-size: 25px;
			}
		}
	}

	.graph-lines {
		fill: none;
		stroke-width: 2px;

		* {
			vector-effect: non-scaling-stroke;
		}

		path:nth-child(1) {
			stroke: #ff71f8;
		}

		path:nth-child(2) {
			stroke: #0057ff;
		}

		path:nth-child(3) {
			stroke: #00a37e;
		}

		path:nth-child(4) {
			stroke: #f29100;
		}
	}

	.labels-y {
		text {
			alignment-baseline: central;
			text-anchor: end;
			fill: #3c3c3b;
			font-weight: 400;

			&:last-child,
			&:first-child {
				opacity: 0.2;
			}

			&:nth-last-child(2),
			&:nth-child(2) {
				opacity: 0.5;
			}
		}
	}

	.labels-x {
		text {
			alignment-baseline: central;
			text-anchor: middle;
			fill: #3c3c3b;
			font-weight: 400;
		}
	}
`;

const StyledWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.5%;

	@media (max-width: 900px) {
		width: calc(100% - 108px);
		margin-left: 90px;
	}
`;

export default () => (
	<StyledWrapper>
		<StyledValueGraph xmlns="http://www.w3.org/2000/svg">
			<defs>
				<clipPath id="container">
					<rect x="0" y="0" width="100%" height="100%" rx="20px" />
				</clipPath>
				<linearGradient id="gradient" gradientTransform="rotate(90)">
					<stop stopColor="#1C93FF" offset="0%" />
					<stop stopColor="#0059FF" offset="100%" />
				</linearGradient>
			</defs>
			<g>
				<g clipPath="url(#container)">
					<svg
						className="graph-fills"
						viewBox="0 0 100 100"
						preserveAspectRatio="none"
					>
						<path d="M0 100 L0 46.5 10.0 44.7 50.0 37.7 97.0 33.6 100.0 33.0 100.0 100.0" />
						<path d="M0 100 L0 53.6 10.0 51.9 50.0 47.2 97.0 41.0 100.0 40.4 100.0 100.0" />
						<path d="M0 100 L0 64.4 10.0 63.6 50.0 53.8 97.0 46.5 100.0 45.3 100.0 100.0" />
						<path d="M0 100 L0 68.6 10.0 68.0 50.0 59.3 97.0 55.0 100.0 53.1 100.0 100.0" />
					</svg>
					<svg
						className="graph-lines"
						viewBox="0 0 100 100"
						preserveAspectRatio="none"
					>
						<path d="M0 46.5 10.0 44.7 50.0 37.7 97.0 33.6 100.0 33.0" />
						<path d="M0 53.6 10.0 51.9 50.0 47.2 97.0 41.0 100.0 40.4" />
						<path d="M0 64.4 10.0 63.6 50.0 53.8 97.0 46.5 100.0 45.3" />
						<path d="M0 68.6 10.0 68.0 50.0 59.3 97.0 55.0 100.0 53.1" />
					</svg>
					<g className="gridlines">
						<line x1="50%" y1="0" x2="50%" y2="100%" />
						<line x1="10%" y1="0" x2="10%" y2="100%" />
						<line x1="97%" y1="0" x2="97%" y2="100%" />
						<line x1="0" y1="16.5%" x2="100%" y2="16.5%" />
						<line x1="0" y1="33%" x2="100%" y2="33%" />
						<line x1="0" y1="50%" x2="100%" y2="50%" />
						<line x1="0" y1="66.5%" x2="100%" y2="66.5%" />
						<line x1="0" y1="83%" x2="100%" y2="83%" />
					</g>
				</g>
				<rect
					className="container"
					x="0"
					y="0"
					width="100%"
					height="100%"
					rx="20px"
				/>
				<g className="labels-y">
					<text x="-20px" y="0">
						€ 800.000,-
					</text>
					<text x="-20px" y="16.5%">
						€ 700.000,-
					</text>
					<text x="-20px" y="33%">
						€ 600.000,-
					</text>
					<text x="-20px" y="50%">
						€ 500.000,-
					</text>
					<text x="-20px" y="66.5%">
						€ 400.000,-
					</text>
					<text x="-20px" y="83%">
						€ 300.000,-
					</text>
					<text x="-20px" y="100%">
						€ 200.000,-
					</text>
				</g>
				<g className="labels-x">
					<svg y="100%">
						<text x="10%" y="20px">
							2018
						</text>
						<text x="50%" y="20px">
							2019
						</text>
						<text x="97%" y="20px">
							2020
						</text>
					</svg>
				</g>
				<g className="vertices">
					<g>
						<svg x="10.0%" y="44.7%">
							<circle r="10px" />
						</svg>
						<svg x="50.0%" y="37.7%">
							<circle r="10px" />
						</svg>
						<svg x="97.0%" y="33.6%">
							<circle r="10px" />
						</svg>
					</g>
					<g>
						<svg x="10.0%" y="51.9%">
							<circle r="10px" />
						</svg>
						<svg x="50.0%" y="47.2%">
							<circle r="10px" />
						</svg>
						<svg x="97.0%" y="41.0%">
							<circle r="10px" />
						</svg>
					</g>
					<g>
						<svg x="10.0%" y="63.6%">
							<circle r="10px" />
						</svg>
						<svg x="50.0%" y="53.8%">
							<circle r="10px" />
						</svg>
						<svg x="97.0%" y="46.5%">
							<circle r="10px" />
						</svg>
					</g>
					<g>
						<svg x="10.0%" y="68.0%">
							<circle r="10px" />
						</svg>
						<svg x="50.0%" y="59.3%">
							<circle r="10px" />
						</svg>
						<svg x="97.0%" y="55.0%">
							<circle r="10px" />
						</svg>
					</g>
				</g>
			</g>
		</StyledValueGraph>
		<img
			style={{
				width: '226px',
				position: 'absolute',
				left: '97%',
				top: '41%',
				marginLeft: '-184px',
				marginTop: '-108px',
			}}
			src={BalloonGraphic}
		/>
	</StyledWrapper>
);
