import React from 'react';
import styled from 'styled-components';
import LogoGraphic from '../graphics/graphic-logo.svg';
import CallIcon from '../graphics/icon-call.svg';
import WebIcon from '../graphics/icon-web.svg';
import EmailIcon from '../graphics/icon-email.svg';
import AddressIcon from '../graphics/icon-address.svg';

const StyledHeader = styled.div`
	display: flex;
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	flex-wrap: wrap;
	padding: 64px;
	background-color: #e6fbff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
	position: relative;
	z-index: 6;

	.logo {
		margin-right: 16px;
		margin-bottom: 32px;
	}

	.wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-right: 16px;

		.top {
			flex: 1;
			font-weight: 400;
			font-size: 45px;
			line-height: 50px;
			margin-bottom: 120px;
		}

		.bottom {
			.title {
				font-size: 32px;
				line-height: 45px;
				margin-bottom: 48px;
			}
		}
	}

	.contact {
		flex-basis: 352px;
		display: flex;
		flex-direction: column;

		.top {
			flex: 1;
		}

		.bottom {
			margin-top: 24px;
		}
	}

	.info {
		border-left: 2px solid #3c3c3b;
		padding-left: 12px;
	}

	.info + .info {
		margin-top: 24px;
	}

	.info-with-icon + .info-with-icon {
		margin-top: 24px;
	}

	.info-with-icon {
		display: grid;
		grid-template-columns: 60px 1fr;
		align-items: center;

		.icon {
			text-align: center;
		}

		.info {
			grid-column: 2;
		}
	}

	@media (max-width: 900px) {
		display: block;

		.icon {
			display: none;
		}

		.info-with-icon {
			display: block;
		}

		.info-with-icon:nth-child(1) {
			margin-top: 24px;
		}
	}

	@media (max-width: 600px) {
		padding: 64px 32px;
	}
`;

export default (props) => (
	<StyledHeader>
		<div className="logo">
			<img src={LogoGraphic} />
		</div>
		<div className="wrapper">
			<div className="top">
				Gemeente
				<br />
				Juinen
			</div>
			<div className="bottom">
				<div className="title">
					Taxatieverslag
					<br />
					Pieterstraat 11, 6515 LE Juinen
				</div>
				<div className="info">
					<div className="key">Eigenaar</div>
					<div className="value">Mevrouw H.P. Janssen</div>
				</div>
				<div className="info">
					<div className="key">Belastingjaar</div>
					<div className="value">2021</div>
				</div>
			</div>
		</div>
		<div className="contact">
			<div className="top">
				<div className="info-with-icon">
					<div className="icon">
						<img src={AddressIcon} />
					</div>
					<div className="info">
						<div className="key">Adres</div>
						<div className="value">Postbus 10, 6521 AB Juinen</div>
					</div>
				</div>
				<div className="info-with-icon">
					<div className="icon">
						<img src={CallIcon} />
					</div>
					<div className="info">
						<div className="key">Telefoon</div>
						<div className="value">(024) 233 50 50</div>
					</div>
				</div>
				<div className="info-with-icon">
					<div className="icon">
						<img src={WebIcon} />
					</div>
					<div className="info">
						<div className="key">Website</div>
						<div className="value">www.juinen.nl/woz</div>
					</div>
				</div>
				<div className="info-with-icon">
					<div className="icon">
						<img src={EmailIcon} />
					</div>
					<div className="info">
						<div className="key">E-mail</div>
						<div className="value">woz@juinen.nl</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="info-with-icon">
					<div className="info">
						<div className="key">Datum</div>
						<div className="value">15 februari 2022</div>
					</div>
				</div>
				<div className="info-with-icon">
					<div className="info">
						<div className="key">Ons kenmerk</div>
						<div className="value">9139 7646 8897 2021 01</div>
					</div>
				</div>
			</div>
		</div>
	</StyledHeader>
);
