import React from 'react';
import styled from 'styled-components';

export const StyledSmallSection = styled.div`
	padding: 64px 64px 64px 171px;
	font-weight: 400;
	background-color: ${(props) => props.background};

	.icon {
		margin-bottom: 32px;
	}

	.header {
		color: #0057ff;
		font-size: 22px;
		line-height: 27px;
		flex: 0 0 280px;
		margin-bottom: 64px;
		width: 280px;
		margin-right: 64px;
	}

	.introduction {
		max-width: 780px;
		font-size: 18px;
		line-height: 28px;
	}

	.main {
		& > div {
			margin-top: 128px;
		}
	}

	@media (max-width: 900px) {
		padding: 64px 64px 128px 64px;

		.header {
			width: 100%;
		}
	}

	@media (max-width: 600px) {
		padding: 32px 16px;

		.main {
			margin: 0 16px 32px;
		}
	}
`;

export default (props) => {
	const { icon, header, children, background } = props;

	return (
		<StyledSmallSection background={background || 'transparent'}>
			<div className="icon">
				<img src={icon} />
			</div>
			<div className="header">
				<h2 data-header={header}>{header}</h2>
			</div>
			{React.Children.toArray(children)[0]}
			<div className="main">{React.Children.toArray(children).slice(1)}</div>
		</StyledSmallSection>
	);
};
