export default {
	property: {
		id: 34,
		location: {
			neighborhood: 'Waterkwartier',
			muncipality: 'Juinen',
		},
		characteristics: {
			type: 'Vrijstaand',
			yearBuilt: 1971,
			buildingSize: 152,
			landSize: 690,
			annex: {
				size: 20,
			},
			detachedStorage: {
				size: 12,
			},
			annexStorage: null,
			awning: null,
			garage: null,
			dormers: { count: 1 },
		},
		sustainability: {
			energyLabel: 'B',
			solarPanels: null,
		},
		quality: {
			maintenanceRating: 0.4,
			qualityRating: 0.6,
			serviceRating: 0.8,
		},
		sale: {
			price: 320000,
			date: '19 februari 1990',
		},
		worth: {
			value: 560000,
		},
		comparableProperties: [
			{
				id: 512,
				location: {
					neighborhood: 'Waterkwartier',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Rijwoning',
					yearBuilt: 1978,
					buildingSize: 146,
					landSize: 146,
					annex: {
						size: 30,
					},
					detachedStorage: {
						size: 7,
					},
					annexStorage: null,
					awning: {},
					garage: null,
					dormers: { count: 1 },
				},
				sustainability: {
					energyLabel: 'D',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.6,
					serviceRating: 0.6,
				},
				sale: {
					price: 325000,
					date: '11 mei 2020',
				},
				worth: {
					value: 360000,
				},
			},
			{
				id: 142,
				location: {
					neighborhood: 'Hazenkamp',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Rijwoning',
					yearBuilt: 1969,
					buildingSize: 139,
					landSize: 139,
					annex: null,
					detachedStorage: null,
					annexStorage: {
						size: 3,
					},
					awning: null,
					garage: null,
					dormers: null,
				},
				sustainability: {
					energyLabel: 'A',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.4,
					qualityRating: 0.6,
					serviceRating: 0.4,
				},
				sale: {
					price: 221000,
					date: '21 oktober 2019',
				},
				worth: {
					value: 260000,
				},
			},
			{
				id: 331,
				location: {
					neighborhood: 'Weksels Woud',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Hoekwoning',
					yearBuilt: 1974,
					buildingSize: 122,
					landSize: 130,
					annex: {
						size: 20,
					},
					detachedStorage: {
						size: 11,
					},
					annexStorage: null,
					awning: null,
					garage: {
						size: 15,
					},
					dormers: null,
				},
				sustainability: {
					energyLabel: 'D',
					solarPanels: {
						count: 15,
					},
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.6,
					serviceRating: 1.0,
				},
				sale: {
					price: 412000,
					date: '19 februari 2020',
				},
				worth: {
					value: 450000,
				},
			},
			{
				id: 721,
				location: {
					neighborhood: 'Koningsdaal',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Vrijstaand',
					yearBuilt: 2017,
					buildingSize: 166,
					landSize: 1301,
					annex: null,
					detachedStorage: null,
					annexStorage: {
						size: 35,
					},
					awning: null,
					garage: null,
					dormers: { count: 1 },
				},
				sustainability: {
					energyLabel: 'A',
					solarPanels: {
						count: 36,
					},
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.8,
					serviceRating: 0.8,
				},
				sale: {
					price: 542000,
					date: '11 juli 2020',
				},
				worth: {
					value: 582000,
				},
			},
			{
				id: 3536,
				location: {
					neighborhood: 'Koningsdaal',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Vrijstaand',
					yearBuilt: 1981,
					buildingSize: 140,
					landSize: 700,
					annex: null,
					detachedStorage: {
						size: 12,
					},
					annexStorage: {
						size: 15,
					},
					awning: {
						size: 15,
					},
					garage: {
						size: 30,
					},
					dormers: {
						count: 2,
					},
				},
				sustainability: {
					energyLabel: 'A',
					solarPanels: 24,
				},
				quality: {
					maintenanceRating: 0.8,
					qualityRating: 0.6,
					serviceRating: 0.8,
				},
				sale: {
					price: 365000,
					date: '14 maart 2019',
				},
				worth: {
					value: 571000,
				},
			},
			{
				id: 457,
				location: {
					neighborhood: 'Waterkwartier',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Hoekwoning',
					yearBuilt: 1991,
					buildingSize: 122,
					landSize: 343,
					annex: {
						size: 20,
					},
					detachedStorage: {
						size: 9,
					},
					annexStorage: null,
					awning: {
						size: 9,
					},
					garage: {
						size: 12,
					},
					dormers: {
						count: 1,
					},
				},
				sustainability: {
					energyLabel: 'C',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.6,
					serviceRating: 0.6,
				},
				sale: {
					price: 181000,
					date: '11 september 2020',
				},
				worth: {
					value: 391000,
				},
			},
			{
				id: 12,
				location: {
					neighborhood: 'Waterkwartier',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Hoekwoning',
					yearBuilt: 2011,
					buildingSize: 111,
					landSize: 253,
					annex: null,
					detachedStorage: {
						size: 6,
					},
					annexStorage: null,
					awning: {
						size: 6,
					},
					garage: {
						size: 9,
					},
					dormers: {
						count: 2,
					},
				},
				sustainability: {
					energyLabel: 'B',
					solarPanels: 9,
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.4,
					serviceRating: 0.8,
				},
				sale: {
					price: 406000,
					date: '20 maart 2020',
				},
				worth: {
					value: 411000,
				},
			},
			{
				id: 434,
				location: {
					neighborhood: 'Hazenkamp',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Rijwoning',
					yearBuilt: 2002,
					buildingSize: 93,
					landSize: 222,
					annex: {
						size: 15,
					},
					detachedStorage: null,
					annexStorage: null,
					awning: null,
					garage: null,
					dormers: null,
				},
				sustainability: {
					energyLabel: 'B',
					solarPanels: 4,
				},
				quality: {
					maintenanceRating: 0.6,
					qualityRating: 0.6,
					serviceRating: 0.8,
				},
				sale: {
					price: 297000,
					date: '7 maart 2019',
				},
				worth: {
					value: 345000,
				},
			},
			{
				id: 468,
				location: {
					neighborhood: 'Waterkwartier',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Rijwoning',
					yearBuilt: 2016,
					buildingSize: 96,
					landSize: 190,
					annex: null,
					detachedStorage: {
						size: 6,
					},
					annexStorage: null,
					awning: {
						size: 9,
					},
					garage: null,
					dormers: null,
				},
				sustainability: {
					energyLabel: 'A',
					solarPanels: 6,
				},
				quality: {
					maintenanceRating: 1,
					qualityRating: 0.8,
					serviceRating: 1,
				},
				sale: {
					price: 311000,
					date: '21 november 2020',
				},
				worth: {
					value: 342000,
				},
			},
			{
				id: 421,
				location: {
					neighborhood: 'Koningsdaal',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Hoekwoning',
					yearBuilt: 1971,
					buildingSize: 112,
					landSize: 256,
					annex: {
						size: 12,
					},
					detachedStorage: {
						size: 9,
					},
					annexStorage: null,
					awning: {
						size: 9,
					},
					garage: {
						size: 12,
					},
					dormers: null,
				},
				sustainability: {
					energyLabel: 'B',
					solarPanels: 9,
				},
				quality: {
					maintenanceRating: 0.4,
					qualityRating: 0.6,
					serviceRating: 0.8,
				},
				sale: {
					price: 198000,
					date: '24 oktober 2020',
				},
				worth: {
					value: 387000,
				},
			},
			{
				id: 797,
				location: {
					neighborhood: 'Koningsdaal',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Vrijstaand',
					yearBuilt: 1962,
					buildingSize: 132,
					landSize: 961,
					annex: {
						size: 32,
					},
					detachedStorage: {
						size: 15,
					},
					annexStorage: {
						size: 12,
					},
					awning: null,
					garage: {
						size: 26,
					},
					dormers: null,
				},
				sustainability: {
					energyLabel: 'D',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.4,
					qualityRating: 0.6,
					serviceRating: 0.6,
				},
				sale: {
					price: 492000,
					date: '4 januari 2019',
				},
				worth: {
					value: 554000,
				},
			},
			{
				id: 124,
				location: {
					neighborhood: 'Weksels Woud',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Vrijstaand',
					yearBuilt: 1902,
					buildingSize: 146,
					landSize: 3210,
					annex: null,
					detachedStorage: null,
					annexStorage: {
						size: 9,
					},
					awning: {
						size: 12,
					},
					garage: {
						size: 15,
					},
					dormers: {
						count: 3,
					},
				},
				sustainability: {
					energyLabel: 'E',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.4,
					qualityRating: 0.6,
					serviceRating: 0.6,
				},
				sale: {
					price: 309000,
					date: '15 januari 2019',
				},
				worth: {
					value: 634000,
				},
			},
			{
				id: 54,
				location: {
					neighborhood: 'Koningsdaal',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Vrijstaand',
					yearBuilt: 2001,
					buildingSize: 166,
					landSize: 6512,
					annex: {
						size: 22,
					},
					detachedStorage: null,
					annexStorage: {
						size: 7,
					},
					awning: null,
					garage: {
						size: 20,
					},
					dormers: {
						count: 1,
					},
				},
				sustainability: {
					energyLabel: 'C',
					solarPanels: null,
				},
				quality: {
					maintenanceRating: 0.8,
					qualityRating: 0.8,
					serviceRating: 0.8,
				},
				sale: {
					price: 660000,
					date: '26 november 2020',
				},
				worth: {
					value: 723000,
				},
			},
			{
				id: 5987,
				location: {
					neighborhood: 'Weksels Woud',
					muncipality: 'Juinen',
				},
				characteristics: {
					type: 'Hoekwoning',
					yearBuilt: 2004,
					buildingSize: 124,
					landSize: 276,
					annex: null,
					detachedStorage: {
						size: 6,
					},
					annexStorage: null,
					awning: null,
					garage: {
						size: 11,
					},
					dormers: null,
				},
				sustainability: {
					energyLabel: 'B',
					solarPanels: 8,
				},
				quality: {
					maintenanceRating: 1,
					qualityRating: 0.8,
					serviceRating: 0.6,
				},
				sale: {
					price: 319000,
					date: '7 oktober 2020',
				},
				worth: {
					value: 406000,
				},
			},
		],
	},
};
