import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Header from './components/Header';

import ResetStyle from './components/ResetStyle';
import FontStyle from './components/FontStyle';
import GlobalStyle from './components/GlobalStyle';

import defaultData from './data/defaultData';
import Navigation from './components/Navigation';
import Section from './components/Section';
import SmallSection, { StyledSmallSection } from './components/SmallSection';

import SectionIcon0 from './graphics/icon-section-0.svg';
import SectionIcon1 from './graphics/icon-section-1.svg';
import SectionIcon2 from './graphics/icon-section-2.svg';
import SectionIcon3 from './graphics/icon-section-3.svg';
import SectionIcon4 from './graphics/icon-section-4.svg';
import SectionIcon5 from './graphics/icon-section-5.svg';
import SectionIcon6 from './graphics/icon-section-6.svg';
import SectionIcon7 from './graphics/icon-section-7.svg';
import SectionIcon8 from './graphics/icon-section-8.svg';
import StreetGraphic from './graphics/graphic-street.svg';
import ValueGraph from './components/ValueGraph';
import ButtonCall from './graphics/button-call.svg';
import ButtonEmail from './graphics/button-email.svg';
import ButtonChat from './graphics/button-chat.svg';
import ButtonWhatsApp from './graphics/button-whatsapp.svg';

import MockupSection3 from './graphics/mockups/mockup-section-3.png';
import MockupSection5 from './graphics/mockups/mockup-section-5.png';
import MockupSection6 from './graphics/mockups/mockup-section-6.png';
import MockupSection7A from './graphics/mockups/mockup-section-7-a.png';
import MockupSection7B from './graphics/mockups/mockup-section-7-b.png';
import MockupSection7C from './graphics/mockups/mockup-section-7-c.png';
import MockupSection8 from './graphics/mockups/mockup-section-8.png';
import HelpButtons from './components/HelpButtons';
import ComparisonTable from './components/ComparisonTable';
import Map from './components/Map';

export const StyledApp = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background: #fff8f0;
	overflow: scroll;
	color: #3c3c3b;
	position: absolute;
	left: 0;
	top: 0;

	.animation {
		font-size: 18px;
	}

	.contact-buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 32px;

		div {
			width: 143px;
			text-align: center;
			font-size: 18px;
			line-height: 25px;
			margin-bottom: 32px;

			img {
				margin-bottom: 16px;
			}
		}
	}
`;

const DoubleSection = styled.div`
	display: flex;
	flex-wrap: wrap;

	${StyledSmallSection} {
		flex: 1;
	}
`;

const App = () => (
	<StyledApp>
		<ResetStyle />
		<FontStyle />
		<GlobalStyle />
		<Header />
		<Navigation />
		<Section icon={SectionIcon0} header="Uw taxatieverslag">
			<div className="introduction">
				<p>Beste mevrouw Janssen,</p>
				<p>
					Dit is uw persoonlijke taxatieverslag met uw WOZ-waarde. Deze waarde
					is het resultaat van onze analyse van uw woning en alle andere
					woningen in Juinen. In dit verslag leggen wij deze analyse uit. Ook
					zetten wij de belangrijkste gegevens op een rij voor u.
				</p>
			</div>
		</Section>
		<DoubleSection>
			<SmallSection
				background="#E6FBFF"
				icon={SectionIcon1}
				header="Uw WOZ-waarde"
			>
				<div className="introduction">
					<p>Op 1 januari 2020 is de WOZ-waarde van uw woning € 560.000,-.</p>
				</div>
				<ValueGraph />
				<div style={{ marginTop: '64px' }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="72"
						height="16"
						style={{
							overflow: 'visible',
							verticalAlign: 'middle',
							marginRight: '24px',
						}}
					>
						<line
							x1="0"
							y1="8"
							x2="28"
							y2="8"
							stroke="#0057ff"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<line
							x1="44"
							y1="8"
							x2="72"
							y2="8"
							stroke="#0057ff"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<circle
							cx="36"
							cy="8"
							r="8"
							stroke="#0057ff"
							strokeWidth="2"
							fill="none"
						/>
					</svg>
					<span>
						Uw&nbsp;
						<span style={{ color: '#0057ff' }}>WOZ-waarde</span>
					</span>
				</div>
				<div style={{ marginTop: '16px', display: 'flex' }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="72"
						height="16"
						style={{
							overflow: 'visible',
							verticalAlign: 'middle',
							marginRight: '24px',
						}}
					>
						<line
							x1="0"
							y1="8"
							x2="28"
							y2="8"
							stroke="#f29100"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<line
							x1="44"
							y1="8"
							x2="72"
							y2="8"
							stroke="#f29100"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<circle
							cx="36"
							cy="8"
							r="8"
							stroke="#f29100"
							strokeWidth="2"
							fill="none"
						/>
					</svg>
					<span>
						Gemiddelde WOZ-waarde in de&nbsp;
						<span style={{ color: '#f29100' }}>gemeente Juinen</span>
					</span>
				</div>
				<div style={{ marginTop: '16px', display: 'flex' }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="72"
						height="16"
						style={{
							overflow: 'visible',
							verticalAlign: 'middle',
							marginRight: '24px',
						}}
					>
						<line
							x1="0"
							y1="8"
							x2="28"
							y2="8"
							stroke="#00a37e"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<line
							x1="44"
							y1="8"
							x2="72"
							y2="8"
							stroke="#00a37e"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<circle
							cx="36"
							cy="8"
							r="8"
							stroke="#00a37e"
							strokeWidth="2"
							fill="none"
						/>
					</svg>
					<span>
						Gemiddelde WOZ-waarde in de&nbsp;
						<span style={{ color: '#00a37e' }}>wijk Weksels Woud</span>
					</span>
				</div>
				<div style={{ marginTop: '16px', display: 'flex' }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="72"
						height="16"
						style={{
							overflow: 'visible',
							verticalAlign: 'middle',
							marginRight: '24px',
						}}
					>
						<line
							x1="0"
							y1="8"
							x2="28"
							y2="8"
							stroke="#ff71f8"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<line
							x1="44"
							y1="8"
							x2="72"
							y2="8"
							stroke="#ff71f8"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<circle
							cx="36"
							cy="8"
							r="8"
							stroke="#ff71f8"
							strokeWidth="2"
							fill="none"
						/>
					</svg>
					<span>
						Gemiddelde WOZ-waarde van alle&nbsp;
						<span style={{ color: '#ff71f8' }}>vrijstaande huizen</span>
						&nbsp;binnen de gemeente Juinen
					</span>
				</div>
			</SmallSection>
			<SmallSection
				background="#EAECFF"
				icon={SectionIcon2}
				header="De woningmarkt in uw wijk"
			>
				<div className="introduction">
					<p>
						Uw woning ligt in de wijk Weksels Woud. Ook van de huizen uit uw
						wijk houden wij kenmerken bij. Verder houden wij gegevens bij over
						alle woningen die de afgelopen jaren in uw wijk zijn verkocht. Zo
						analyseren wij de woningmarkt.
					</p>
					<p>
						Deze kaart geeft u een algemene indruk van de woningen, de
						woningmarkt en de voorzieningen in uw wijk.
					</p>
					<p>
						<a
							href="https://opendata.cbs.nl/statline/?dl=37596#/CBS/nl/dataset/84583NED/table?ts=1587473808276"
							target="_blank"
						>
							Wilt u meer gegevens inzien over onze gemeente of uw wijk? Hier
							vindt u de meest actuele informatie →
						</a>
					</p>
				</div>
				<Map />
			</SmallSection>
		</DoubleSection>
		<Section
			icon={SectionIcon3}
			header="Wij analyseren overeenkomsten en verschillen"
		>
			<div className="introduction">
				<p>
					Een team van WOZ-deskundigen vergelijkt uw woning met de honderden
					woningen die de afgelopen jaren zijn verkocht in Juinen. Wij
					analyseren nauwkeurig de overeenkomsten en verschillen tussen uw
					woning en de woningen die recent verkocht zijn. Wij zien bijvoorbeeld
					dat een woning met garage vaak een hogere verkoopprijs heeft dan een
					woning zonder garage. Ook de afmeting van een woning bepaalt de
					verkoopprijs. Zo kijken we naar tientallen woningkenmerken.
				</p>
				<p>
					Hieronder ziet u een tabel die de overeenkomsten en verschillen tussen
					verkochte woningen laat zien. Vergelijk zelf uw woning met andere
					woningen uit de gemeente Juinen.
				</p>
			</div>
			<ComparisonTable data={defaultData} />
		</Section>
		<Section
			background="#EAECFF"
			icon={SectionIcon4}
			header="WOZ-waarden in juiste verhouding"
		>
			<div className="introduction">
				<p>
					Ook analyseren wij de woningen die niet verkocht zijn. Wij zorgen
					ervoor dat de WOZ-waarden van alle woningen in goede verhouding staan
					tot elkaar. Hieronder ziet u de WOZ-waarden van een aantal woningen
					uit uw straat. Bij elke woning is aangegeven welke kenmerken invloed
					hebben op de waarde. Dit geeft inzicht in de verhouding tussen de
					waarden.
				</p>
				<p>
					<a href="https://www.wozwaardeloket.nl/" target="_blank">
						Bij het WOZ-waardeloket kunt u zelf de waarde van andere woningen
						bekijken. Ga hiervoor naar wozwaardeloket.nl →
					</a>
				</p>
			</div>
			<div
				style={{
					color: '#0057ff',
					fontSize: '32px',
					lineHeight: '42px',
					margin: '0 64px',
				}}
			>
				Pieterstraat in Juinen
			</div>
			<div
				style={{
					overflowX: 'scroll',
					width: '100vw',
				}}
			>
				<img
					src={StreetGraphic}
					style={{ display: 'block', margin: '0 auto', maxHeight: '65vh' }}
				/>
			</div>
		</Section>
		<Section icon={SectionIcon5} header="Wat is modelmatig waarderen?">
			<div className="introduction">
				<p>
					De gemeente Juinen moet elk jaar alle duizenden woningen en andere
					gebouwen in de gemeente waarderen. Dit is een enorme taak die wij zo
					nauwkeurig mogelijk uitvoeren. Want elke WOZ-waarde moet kloppen.
					Daarom verzamelen wij het hele jaar door gegevens over woningen en
					over verkoopprijzen. Ons team van deskundigen zet deze gegevens in het
					computermodel. Dit computermodel vergelijkt uw woning met alle
					woningen die de afgelopen jaren zijn verkocht. Zo gebruiken wij de
					enorme rekenkracht van de computer.
				</p>
				<div
					style={{
						margin: '32px 0 32px 0',
						overflowX: 'scroll',
						maxWidth: 'calc(100vw - 64px)',
					}}
				>
					<img
						src={MockupSection5}
						style={{ display: 'block', margin: '0 auto', maxHeight: '65vh' }}
					/>
				</div>
				<p>Deze aanpak heet modelmatig waarderen. De voordelen hiervan zijn:</p>
				<ol
					style={{
						margin: '1em 3em',
						color: '#0057FF',
						listStyle: 'decimal',
					}}
				>
					<li>Het computermodel is zeer betrouwbaar.</li>
					<li>Wij gebruiken altijd de meest actuele gegevens.</li>
					<li>De modellen zijn onafhankelijk en objectief.</li>
				</ol>
				<p>
					De WOZ-waarde is net als iedere taxatie een zo goed mogelijke
					inschatting van de marktwaarde.
				</p>
				<p>
					<a
						href="https://www.nijmegen.nl/diensten/belastingen/woz-waarde-en-woz-aanslag/"
						target="_blank"
					>
						Hier vindt u meer achtergrond over de totstandkoming van uw
						WOZ-waarde →
					</a>
				</p>
			</div>
		</Section>
		<Section
			background="#EAECFF"
			icon={SectionIcon6}
			header="Wat is de WOZ-waarde eigenlijk?"
		>
			<div className="introduction">
				<p>WOZ is de afkorting van de wet Waardering Onroerende Zaken.</p>
				<p>
					De WOZ-waarde is altijd de waarde die de woning had op 1 januari van
					het vorige kalenderjaar. Dit noemen wij de waardepeildatum. De
					waardepeildatum bij dit verslag is dus 1 januari 2020.
				</p>
				<div
					style={{
						margin: '32px 0 32px 0',
						overflowX: 'scroll',
						maxWidth: 'calc(100vw - 64px)',
					}}
				>
					<img
						src={MockupSection6}
						style={{ display: 'block', margin: '0 auto', maxHeight: '65vh' }}
					/>
				</div>
				<p>
					De WOZ-waarde van uw woning bepaalt hoeveel onroerendezaakbelasting en
					waterschapsbelasting u betaalt. Daarnaast bepaalt de WOZ-waarde ook
					een aantal andere belastingen, zoals de erf- en inkomstenbelasting.
				</p>
				<p>
					<a
						href="https://www.nijmegen.nl/diensten/belastingen/woz-waarde-en-woz-aanslag/"
						target="_blank"
					>
						Hier vindt u meer achtergrond over de totstandkoming van uw
						WOZ-waarde →
					</a>
				</p>
			</div>
		</Section>
		<Section icon={SectionIcon7} header="Hoe wordt mijn WOZ-waarde gebruikt?">
			<div className="introduction">
				<p>
					Veel mensen denken dat wij als gemeente Juinen profiteren van een zo
					hoog mogelijke WOZ-waarde. Dat is een misverstand. In werkelijkheid
					berekent de gemeente hoeveel geld er nodig is om de gemeentelijke
					taken en voorzieningen op peil te houden.
				</p>
				<div>
					<img
						src={MockupSection7A}
						style={{
							maxWidth: '420px',
							width: '100%',
							margin: '64px auto',
							display: 'block',
						}}
					/>
				</div>
				<p>
					Vervolgens worden de WOZ-waarden van alle woningen uit Juinen bij
					elkaar opgeteld.
{' '}
				</p>
				<div>
					<img
						src={MockupSection7B}
						style={{
							maxWidth: '420px',
							width: '100%',
							margin: '64px auto',
							display: 'block',
						}}
					/>
				</div>
				<p>
					Het totaalbedrag dat de gemeente aan belastingopbrengsten nodig heeft,
					wordt gedeeld door de totale som van WOZ-waarden. Het resultaat van
					deze rekensom bepaalt het tarief van de onroerendezaakbelasting in
					onze gemeente.
				</p>
				<p>
					De WOZ-waarde en het tarief van de onroerendezaakbelasting zijn dus
					met elkaar verbonden. Hogere WOZ-waarden zorgen voor een lager tarief,
					lagere WOZ-waarden zorgen voor een hoger tarief.
				</p>
				<div style={{ width: '100%', maxWidth: '520px', margin: '64px auto' }}>
					<div
						style={{
							width: '100%',
							paddingBottom: '100%',
							position: 'relative',
						}}
					>
						<svg
							className="animation"
							xmlns="http://www.w3.org/2000/svg"
							style={{
								overflow: 'visible',
								position: 'absolute',
								left: 0,
								right: 0,
								top: 0,
								bottom: 0,
								width: '100%',
								height: '100%',
							}}
						>
							<defs>
								<linearGradient id="lrgradient">
									<stop stopColor="#1C93FF" offset="0%" />
									<stop stopColor="#0059FF" offset="100%" />
								</linearGradient>
							</defs>
							<svg id="total" x="0" y="0" width="50%" height="50%">
								<rect
									x="0"
									y="0"
									width="100%"
									height="100%"
									fill="url(#lrgradient)"
								/>
								<svg x="50%" y="50%" style={{ overflow: 'visible' }}>
									<g>
										<text
											x="0"
											y="-15px"
											fill="#fff"
											textAnchor="middle"
											alignmentBaseline="central"
										>
											Totale WOZ-waarde
										</text>
										<text
											x="0"
											y="15px"
											fill="#fff"
											textAnchor="middle"
											alignmentBaseline="central"
										>
											gemeente Juinen
										</text>
										<animateTransform
											attributeName="transform"
											attributeType="XML"
											type="scale"
											values="0.8;1.1;0.8;0.5;0.8;0.8"
											keyTimes="0;0.15;0.3;0.45;0.6;1"
											keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
											calcMode="spline"
											dur="10s"
											start="0s"
											repeatCount="indefinite"
										/>
									</g>
								</svg>
								<animate
									attributeName="width"
									values="50%;65%;50%;35%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									dur="10s"
									start="0s"
									repeatCount="indefinite"
								/>
								<animate
									attributeName="height"
									values="50%;65%;50%;35%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									dur="10s"
									start="0s"
									repeatCount="indefinite"
								/>
							</svg>
							<svg id="tariff" x="50%" y="50%" width="50%" height="50%">
								<rect
									x="0"
									y="0"
									width="100%"
									height="100%"
									fill="url(#lrgradient)"
								/>
								<svg x="50%" y="50%" style={{ overflow: 'visible' }}>
									<g>
										<text
											x="0"
											y="-15px"
											fill="#fff"
											textAnchor="middle"
											alignmentBaseline="central"
										>
											Belastingtarief
										</text>
										<text
											x="0"
											y="15px"
											fill="#fff"
											textAnchor="middle"
											alignmentBaseline="central"
										>
											gemeente Juinen
										</text>
										<animateTransform
											attributeName="transform"
											attributeType="XML"
											type="scale"
											values="0.8;0.5;0.8;1.1;0.8;0.8"
											keyTimes="0;0.15;0.3;0.45;0.6;1"
											keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
											calcMode="spline"
											dur="10s"
											start="0s"
											repeatCount="indefinite"
										/>
									</g>
								</svg>
								<animate
									attributeName="width"
									values="50%;35%;50%;65%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									dur="10s"
									start="0s"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									repeatCount="indefinite"
								/>
								<animate
									attributeName="height"
									values="50%;35%;50%;65%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									dur="10s"
									start="0s"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									repeatCount="indefinite"
								/>
								<animate
									attributeName="x"
									values="50%;65%;50%;35%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									dur="10s"
									start="0s"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									repeatCount="indefinite"
								/>
								<animate
									attributeName="y"
									values="50%;65%;50%;35%;50%;50%"
									keyTimes="0;0.15;0.3;0.45;0.6;1"
									dur="10s"
									start="0s"
									keySplines="0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1 ; 0.5 0 0.5 1"
									calcMode="spline"
									repeatCount="indefinite"
								/>
							</svg>
						</svg>
					</div>
				</div>
				<p>
					De onroerendezaakbelasting die u moet betalen (en enkele andere
					belastingen) wordt bepaald door uw WOZ-waarde te vermenigvuldigen met
					dit tarief. Zo helpt de WOZ-waarde om belastingen over inwoners te
					verdelen op basis van draagkracht.
				</p>
				<div>
					<img
						src={MockupSection7C}
						style={{
							maxWidth: '420px',
							width: '100%',
							margin: '64px auto',
							display: 'block',
						}}
					/>
				</div>
				<p>
					Een hoge WOZ-waarde kan ook voordelig voor u zijn. Bijvoorbeeld,
					wanneer uw WOZ-waarde stijgt, kunt u in aanmerking komen voor een
					lagere hypotheekrente.
				</p>
				<p>
					<a
						href="https://www.nijmegen.nl/diensten/belastingen/woz-waarde-en-woz-aanslag/"
						target="_blank"
					>
						Hier vindt u meer achtergrond over de totstandkoming van uw
						WOZ-waarde →
					</a>
				</p>
			</div>
		</Section>
		<a id="contact" />
		<Section
			background="#EAECFF"
			icon={SectionIcon8}
			header="Heeft u vragen of opmerkingen?"
		>
			<div className="introduction">
				<p>
					Heeft u vragen of klopt er iets niet? Twijfel niet en neem contact met
					ons op. Samen met u zoeken wij naar een oplossing.
				</p>
				<p>U kunt ons op verschillende manieren bereiken.</p>
				<a
					href="https://www.nijmegen.nl/over-de-gemeente/contact/"
					target="_blank"
					style={{ color: 'inherit' }}
				>
					<div className="contact-buttons">
						<div>
							<img src={ButtonCall} width="143" />
							<div>Bel met ons via (010) 798 89 99</div>
						</div>
						<div>
							<img src={ButtonEmail} width="143" />
							<div>Stuur een e-mail</div>
						</div>
						<div>
							<img src={ButtonChat} width="143" />
							<div>Chat met ons</div>
						</div>
						<div>
							<img src={ButtonWhatsApp} width="143" />
							<div>WhatsApp naar 06 26 65 25 83</div>
						</div>
					</div>
				</a>
				<p>
					Komen we er samen toch niet uit? Dan kunt u eenvoudig bezwaar maken
					via onderstaande knop. U ontvangt binnen 24 uur een reactie van ons.
				</p>
				<p>
					<a
						href="https://www.nijmegen.nl/over-de-gemeente/contact/"
						target="_blank"
					>
						Maak hier bezwaar →
					</a>
				</p>
			</div>
		</Section>
		<HelpButtons />
	</StyledApp>
);

ReactDOM.render(<App />, document.querySelector('#app'));
