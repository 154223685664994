import { createGlobalStyle } from 'styled-components';

import CircularStdBookWoff2 from '../fonts/CircularStd-Book.woff2';
import CircularStdMediumWoff2 from '../fonts/CircularStd-Medium.woff2';
import CircularStdBoldWoff2 from '../fonts/CircularStd-Bold.woff2';

export default createGlobalStyle`
	@font-face {
		font-family: 'Circular Standard';
		src: url(${CircularStdBookWoff2});
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Circular Standard';
		src: url(${CircularStdMediumWoff2});
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Circular Standard';
		src: url(${CircularStdBoldWoff2});
		font-weight: 500;
		font-style: normal;
	}

	body {
		font-family: 'Circular Standard';
		font-weight: 300;
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 12px;
	}
`;
