import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import localize from '../data/localize';
import markup from '../data/markup';
import compare from '../data/compare';
import IconArrowLeft from '../graphics/icon-arrow-left.svg';
import IconArrowRight from '../graphics/icon-arrow-right.svg';
import IconCollapse from '../graphics/icon-collapse.svg';
import IconExpand from '../graphics/icon-expand.svg';
import BuildingTypeHoekwoning from '../graphics/buildingType-Hoekwoning.svg';
import BuildingTypeRijwoning from '../graphics/buildingType-Rijwoning.svg';
import BuildingTypeVrijstaand from '../graphics/buildingType-Vrijstaand.svg';

const buildingTypes = {
	Hoekwoning: BuildingTypeHoekwoning,
	Rijwoning: BuildingTypeRijwoning,
	Vrijstaand: BuildingTypeVrijstaand,
};

const minColumnWidth = 150;

const StyledComparisonTable = styled.div`
	position: relative;
	font-weight: 400;
	user-select: none;
`;

const StyledComparisonTableRows = styled.div`
	display: none;
`;

const StyledComparisonTableRow = styled.div`
	display: grid;
	grid-template-columns: ${(props) => props.width}px ${(props) => props.width}px 1fr;

	border-bottom: 1px solid rgb(181, 192, 206);
	background: #fff8f0;

	@media (max-width: 450px) {
		grid-template-columns: 0 50vw 50vw;
		grid-template-rows: 90px;
	}
`;

const StyledComparisonTableSectionHeader = styled.label`
	display: block;
	appearance: none;
	background-color: #266ffa;
	background-image: url(${IconExpand});
	background-size: 21px;
	background-repeat: no-repeat;
	background-position: calc(100% - 25px) center;
	border: none;
	height: 58px;
	margin: 0;
	padding: 0;
	position: sticky;
	top: 125px;
	z-index: 2;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 25px;
	font-size: 18px;
	color: #fff;
	font-weight: 400;
	max-width: 100vw;

	@media (max-width: 600px) {
		top: 48px;
	}
`;

const StyledComparisonTableSectionHeaderCheckbox = styled.input`
	appearance: none;
	width: 0;
	height: 0;
	display: block;
	border: none;
	margin: 0;
	padding: 0;

	&:checked + ${StyledComparisonTableSectionHeader} {
		background-image: url(${IconCollapse});
	}

	&:checked ~ ${StyledComparisonTableRows} {
		display: block;
	}
`;

const StyledComparisonTableCell = styled.div`
	display: flex;
	height: 58px;
	padding: 8px 0;
	background-color: #fff8f0;
	align-items: center;
	position: relative;
	flex-wrap: wrap;
	font-size: 16px;
	box-sizing: border-box;
	box-shadow: inset 1px 0 0 rgb(181, 192, 206);
`;
const StyledComparisonTableRowHeader = styled(StyledComparisonTableCell)`
	box-shadow: inset -1px 0 0 rgb(181, 192, 206);
	color: #266ffa;
	font-size: 16px;
	max-width: 100vw;

	div {
		padding: 0 25px;
	}

	@media (max-width: 450px) {
		width: 100vw;
		height: 32px;
		justify-content: center;
		color: #fff;
		background-color: #004bdb;
	}
`;
const StyledComparisonTableHighlightCell = styled(StyledComparisonTableCell)`
	box-shadow: 1px 0 0 rgb(181, 192, 206);
	z-index: 1;
	justify-content: center;
	background-color: #afcaff;

	@media (max-width: 450px) {
		margin-top: 32px;
	}

	&:after {
		content: '';
		width: 24px;
		height: 100%;
		position: absolute;
		left: 100%;
		top: 0;
		background: linear-gradient(
			to right,
			rgba(0, 0, 0, 0.11),
			rgba(0, 0, 0, 0)
		);
	}

	@media (max-width: 450px) {
		max-width: 50vw;
	}
`;
const StyledComparisonTableComparableCell = styled(StyledComparisonTableCell)`
	flex: 0 0 1;
	justify-content: center;
	background-color: #e3ecff;
`;

const StyledComparisonTableComparableCells = styled(StyledComparisonTableCell)`
	/* flex-grow: ${(props) => props.count}; */
	overflow: hidden;
	display: block;
	padding: 0;

	/* @media (max-width: 450px) {
		max-width: 50vw;
	} */

	@media (max-width: 450px) {
		margin-top: 32px;
	}

	& > div {
		/* display: flex; */
		/* flex-wrap: nowrap; */
		transform: translateX(0);
		transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		width: ${(props) => props.width * props.count}px;
		/* width: ${(props) => Math.round((1 / props.count) * 10000) / 100}%; */
	}

	${StyledComparisonTableComparableCell} {
		width: ${(props) => Math.round((1 / props.count) * 10000) / 100}%;
		float: left;
		/* flex: 0 0 ${(props) => Math.round((1 / props.count) * 10000) / 100}; */
	}
`;

const StyledComparisonTableSection = styled.section`
	& + & {
		margin-top: 16px;
	}

	&:first-child ${StyledComparisonTableRow} {
		border-bottom: 0;

		${StyledComparisonTableComparableCell}, ${StyledComparisonTableHighlightCell} {
			font-weight: 300;
			border-top: 1px solid rgb(181, 192, 206);
			border-radius: 20px 20px 0 0;
			height: 170px;
			text-align: center;

			div {
				flex-basis: 100%;
			}

			img {
				display: block;
			}
		}

		${StyledComparisonTableComparableCells}, ${StyledComparisonTableHighlightCell} {
			box-shadow: none;
			height: 170px;
		}

		${StyledComparisonTableHighlightCell}:after {
			border-radius: 20px 0 0 0;
		}

		${StyledComparisonTableRowHeader} {
			box-shadow: none;
		}

		${StyledComparisonTableRow} {
			border-bottom: 0;
		}
	}

	&:first-child + & {
		margin-top: 0;
	}

	&:last-of-type {
		position: sticky;
		bottom: 0;
		z-index: 3;

		${StyledComparisonTableComparableCell}, ${StyledComparisonTableHighlightCell} {
			font-size: 24px;
			border-bottom: 1px solid rgb(181, 192, 206);
			border-radius: 0 0 20px 20px;
		}

		${StyledComparisonTableComparableCells}, ${StyledComparisonTableHighlightCell} {
			box-shadow: none;
		}

		${StyledComparisonTableHighlightCell}:after {
			border-radius: 0 0 0 20px;
		}

		${StyledComparisonTableRowHeader} {
			box-shadow: none;
		}

		${StyledComparisonTableRow} {
			border-bottom: 0;
		}
	}

	@media (max-width: 450px) {
		&:first-child ${StyledComparisonTableRow} {
			grid-template-rows: 170px;

			${StyledComparisonTableComparableCells}, ${StyledComparisonTableHighlightCell} {
				margin-top: 0;
			}

			${StyledComparisonTableCell} {
				width: 50vw;
			}

			${StyledComparisonTableRowHeader} {
				width: 0;
			}
		}
	}
`;

const StyledButtons = styled.div`
	position: sticky;
	margin-left: calc(
		${(props) =>
				`${
					100 -
					Math.round(
						(props.nComparableVisible / 2 / (props.nComparableVisible + 2)) *
							10000
					) /
						100
				}%`} - 54px
	);
	bottom: -16px;
	transform: translate(0, -126px);
	z-index: 4;
	width: 108px;
	height: 40px;
	background-color: #fdae53;
	border-radius: 20px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);

	&:after {
		background-color: #feddb8;
		width: 1px;
		height: 100%;
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 0;
	}

	@media (max-width: 450px) {
		margin-left: calc(50% - 54px);
	}
`;
const StyledPreviousButton = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 50%;
	height: 100%;
	background: url(${IconArrowLeft}) no-repeat 17px;
	background-size: 20px 16px;

	&.disabled {
		opacity: 0.33;
	}
`;

const StyledNextButton = styled.div`
	background-color: #fdae53;
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 100%;
	background: url(${IconArrowRight}) no-repeat 17px;
	background-size: 20px 16px;

	&.disabled {
		opacity: 0.33;
	}
`;

const StyledCrossSVG = styled.svg`
	line {
		stroke: #ff8b36;
		stroke-width: 2;
		stroke-linecap: round;
	}

	overflow: visible;
	width: 12px;
	height: 12px;
`;

function CrossSVG() {
	return (
		<StyledCrossSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
			<line x1="0" y1="0" x2="8" y2="8" />
			<line x1="0" y1="8" x2="8" y2="0" />
		</StyledCrossSVG>
	);
}

const StyledCheckmarkSVG = styled(StyledCrossSVG)`
	line {
		stroke: #00bc74;
	}
	width: 18px;
	flex-basis: 100%;
`;

function CheckmarkSVG() {
	return (
		<StyledCheckmarkSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8">
			<line x1="0" y1="4" x2="4" y2="8" />
			<line x1="4" y1="8" x2="12" y2="0" />
		</StyledCheckmarkSVG>
	);
}

let resizeListener;

export default (props) => {
	const { data } = props;
	const [columnWidth, setColumnWidth] = useState(0);

	const [nColumnsVisible, setNColumnsVisible] = useState(
		Math.floor(window.innerWidth / minColumnWidth)
	);
	const [nComparableVisible, setNComparableVisible] = useState(
		Math.min(
			Math.max(nColumnsVisible - 2, 1),
			data.property.comparableProperties.length
		)
	);
	const [maxComparableIndex, setMaxComparableIndex] = useState(
		data.property.comparableProperties.length - nComparableVisible
	);
	const [comparableIndex, setComparableIndex] = useState(0);

	function advance(n) {
		const target = Math.min(
			maxComparableIndex,
			Math.max(0, comparableIndex + n)
		);

		if (comparableIndex !== target) setComparableIndex(target);
	}

	function recalculateWidth() {
		const _minColumnWidth = Math.max(
			minColumnWidth,
			Math.min(window.innerWidth / 5, 250)
		);

		const _nColumnsVisible = Math.floor(window.innerWidth / _minColumnWidth);
		const _nComparableVisible = Math.min(
			Math.max(_nColumnsVisible - 2, 1),
			data.property.comparableProperties.length
		);
		const _maxComparableIndex =
			data.property.comparableProperties.length - _nComparableVisible;

		setColumnWidth(window.innerWidth / _nColumnsVisible);

		// if (nColumnsVisible !== _nColumnsVisible)
		setNColumnsVisible(_nColumnsVisible);
		// if (nComparableVisible !== _nComparableVisible)
		setNComparableVisible(_nComparableVisible);
		// if (maxComparableIndex !== _maxComparableIndex)
		setMaxComparableIndex(_maxComparableIndex);
		if (comparableIndex > maxComparableIndex)
			setComparableIndex(maxComparableIndex);
	}

	useEffect(() => {
		recalculateWidth();

		resizeListener = window.addEventListener('resize', recalculateWidth);

		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	function renderCell(content, path) {
		const myContent = path.split('.').reduce((p, k) => p[k], data.property);

		let ret;

		if (content == null) {
			ret = <CrossSVG key="cross" />;
		} else if (typeof content === 'object') {
			const all = [<CheckmarkSVG key="checkmark" />];

			Object.keys(content).forEach((k) => {
				all.push(
					<div key={`${path}.${k}`}>{markup(`${path}.${k}`, content[k])}</div>
				);
			});

			ret = all;
		} else ret = markup(path, content);

		return [ret, compare(path, content, myContent)];
	}

	function renderComparableCells(sectionID, metricID) {
		return data.property.comparableProperties.reduce((cells, property) => {
			cells.push(
				<StyledComparisonTableComparableCell key={property.id}>
					{renderCell(
						property[sectionID][metricID],
						`${sectionID}.${metricID}`
					)}
				</StyledComparisonTableComparableCell>
			);

			return cells;
		}, []);
	}

	function generateRows(sectionID, metricIDs) {
		const comparableScroll = comparableIndex * columnWidth;
		// (Math.round((1 / nComparableVisible) * 10000) / 100) *
		// Math.min(comparableIndex, maxComparableIndex);

		return metricIDs.reduce((rows, metricID) => {
			const localizedHeader = localize(metricID);

			if (localizedHeader) {
				rows.push(
					<StyledComparisonTableRow
						width={columnWidth}
						count={nColumnsVisible}
						key={metricID}
					>
						<StyledComparisonTableRowHeader>
							<div>{localizedHeader}</div>
						</StyledComparisonTableRowHeader>
						<StyledComparisonTableHighlightCell>
							{renderCell(
								data.property[sectionID][metricID],
								`${sectionID}.${metricID}`
							)}
						</StyledComparisonTableHighlightCell>
						<StyledComparisonTableComparableCells
							width={columnWidth}
							count={data.property.comparableProperties.length}
						>
							<div style={{ transform: `translateX(-${comparableScroll}px)` }}>
								{renderComparableCells(sectionID, metricID)}
							</div>
						</StyledComparisonTableComparableCells>
					</StyledComparisonTableRow>
				);
			}

			return rows;
		}, []);
	}

	function generateSections() {
		return Object.keys(data.property).reduce((sections, sectionID) => {
			const localizedHeader = localize(sectionID);

			if (localizedHeader) {
				const metricIDs = Object.keys(data.property[sectionID]);

				sections.push(
					<StyledComparisonTableSection key={sectionID}>
						<StyledComparisonTableSectionHeaderCheckbox
							id={`${sectionID}-header-check`}
							type="checkbox"
							defaultChecked
						/>
						<StyledComparisonTableSectionHeader
							htmlFor={`${sectionID}-header-check`}
						>
							{localizedHeader}
						</StyledComparisonTableSectionHeader>
						<StyledComparisonTableRows>
							{generateRows(sectionID, metricIDs)}
						</StyledComparisonTableRows>
					</StyledComparisonTableSection>
				);
			}

			return sections;
		}, []);
	}

	function generateTitles() {
		const comparableScroll = comparableIndex * columnWidth;
		// (Math.round((1 / nComparableVisible) * 10000) / 100) *
		// Math.min(comparableIndex, maxComparableIndex);

		return (
			<StyledComparisonTableSection>
				<StyledComparisonTableRows style={{ display: 'block' }}>
					<StyledComparisonTableRow width={columnWidth} count={nColumnsVisible}>
						<StyledComparisonTableRowHeader />
						<StyledComparisonTableHighlightCell>
							<div>Uw woning</div>
							<img src={buildingTypes[data.property.characteristics.type]} />
						</StyledComparisonTableHighlightCell>
						<StyledComparisonTableComparableCells
							width={columnWidth}
							count={data.property.comparableProperties.length}
						>
							<div style={{ transform: `translateX(-${comparableScroll}px)` }}>
								{data.property.comparableProperties.reduce(
									(cells, property) => {
										cells.push(
											<StyledComparisonTableComparableCell key={property.id}>
												<div>{`Woning No. ${property.id}`}</div>
												<img
													src={buildingTypes[property.characteristics.type]}
												/>
											</StyledComparisonTableComparableCell>
										);

										return cells;
									},
									[]
								)}
							</div>
						</StyledComparisonTableComparableCells>
					</StyledComparisonTableRow>
				</StyledComparisonTableRows>
			</StyledComparisonTableSection>
		);
	}

	return (
		<StyledComparisonTable>
			{generateTitles()}
			{generateSections()}
			<StyledButtons nComparableVisible={nComparableVisible}>
				<StyledPreviousButton
					className={comparableIndex === 0 ? 'disabled' : ''}
					onClick={() => advance(-1)}
				/>
				<StyledNextButton
					className={comparableIndex === maxComparableIndex ? 'disabled' : ''}
					onClick={() => advance(1)}
				/>
			</StyledButtons>
		</StyledComparisonTable>
	);
};
