import React from 'react';
import styled from 'styled-components';

export const StyledSection = styled.div`
	/* padding: 64px 64px 128px 171px; */
	font-weight: 400;
	display: flex;
	flex-wrap: wrap;
	background-color: ${(props) => props.background};
	align-items: flex-start;

	.icon {
		margin-bottom: 32px;
	}

	.two-columns {
		display: flex;
		flex-wrap: wrap;
		flex-basis: 100%;

		.header {
			color: #0057ff;
			font-size: 32px;
			flex: 0 0 280px;
			line-height: 42px;
			margin-right: 64px;
			margin-bottom: 64px;
		}

		.introduction {
			flex: 0 1 780px;
			font-size: 24px;
			line-height: 38px;

			* {
				max-width: 780px;
			}
		}
	}

	.main {
		flex: 1;
		/* margin-left: -235px; */
		/* position: relative;
		left: 64px; */

		& > div {
			margin-top: 128px;
		}
	}

	@media (max-width: 900px) {
		/* padding: 64px 64px 128px 64px; */

		.main {
			/* margin-left: -128px; */
		}
	}

	@media (max-width: 600px) {
		/* padding: 64px 32px; */

		.main {
			/* margin-left: -96px; */
		}

		.two-columns .header {
			font-size: 18px;
			line-height: 24px;
		}

		.two-columns .introduction {
			font-size: 18px;
			line-height: 24px;
		}
	}

	////////////////
	padding: 64px 0 96px 0;

	.icon,
	.two-columns {
		margin: 0 64px 32px 64px;
	}

	@media (max-width: 600px) {
		padding: 32px 0 32px 0;

		.icon,
		.two-columns {
			margin: 0 16px 16px 16px;
		}
	}
`;

export default (props) => {
	const { icon, header, children, background } = props;

	return (
		<StyledSection background={background}>
			<div className="icon">
				<img src={icon} />
			</div>
			<div className="two-columns">
				<div className="header">
					<h2 data-header={header}>{header}</h2>
				</div>
				{React.Children.toArray(children)[0]}
			</div>
			<div className="main">{React.Children.toArray(children).slice(1)}</div>
		</StyledSection>
	);
};
