import React from 'react';
import styled from 'styled-components';

import ContactButton from '../graphics/button-contact.svg';
import ContactButtonSmall from '../graphics/button-contact-small.svg';

const StyledHelpButtons = styled.div`
	position: fixed;
	right: 25px;
	bottom: 25px;
	display: block;
	z-index: 5;
	width: 200px;
	height: 52px;
	border-radius: 30px;
	box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.16);

	.image {
		width: 100%;
		height: 100%;
		display: block;
		background: url(${ContactButton});
		background-size: 100% 100%;
	}

	@media (max-width: 600px) {
		right: 16px;
		bottom: 30px;
		width: 56px;

		.image {
			background: url(${ContactButtonSmall});
		}
	}
`;

export default () => (
	<StyledHelpButtons>
		<a href="#contact">
			<div className="image" />
		</a>
		{/* <img src={ProblemButton} /> */}
	</StyledHelpButtons>
);
