import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NextIcon from '../graphics/icon-next.svg';
import NavigationIcon0 from '../graphics/icon-navigation-0.svg';
import NavigationIcon1 from '../graphics/icon-navigation-1.svg';
import NavigationIcon2 from '../graphics/icon-navigation-2.svg';
import NavigationIcon3 from '../graphics/icon-navigation-3.svg';
import NavigationIcon4 from '../graphics/icon-navigation-4.svg';
import NavigationIcon5 from '../graphics/icon-navigation-5.svg';
import NavigationIcon6 from '../graphics/icon-navigation-6.svg';
import NavigationIcon7 from '../graphics/icon-navigation-7.svg';
import NavigationIcon8 from '../graphics/icon-navigation-8.svg';

import { StyledApp } from '../app';

const StyledNavigation = styled.div`
	background: #f8f8f8;
	overflow: scroll;
	height: 125px;
	align-items: center;
	display: flex;
	box-sizing: border-box;
	padding: 0 25px;
	color: #0057ff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
	font-weight: 400;
	position: sticky;
	top: 0;
	z-index: 5;

	ul li:nth-child(${(props) => props.index + 1}),
	ul li:hover {
		.label {
			background: linear-gradient(to bottom, #1c93ff, #0057ff);
			border: none;
			padding: 7px 17px;
			color: #f8f8f8;
		}

		cursor: pointer;
	}

	ul {
		display: flex;
		flex-wrap: nowrap;
		margin: 0 auto;

		li {
			flex: 1 0 1;
			text-align: center;
			align-items: center;
			justify-content: center;
			display: flex;
			flex-wrap: nowrap;
			white-space: nowrap;
			position: relative;
			flex-direction: column;

			.icon {
				height: 32px;
				display: flex;
				align-items: center;
				margin-bottom: 12px;
			}

			.label {
				border: 1px solid #0057ff;
				border-radius: 1000px;
				padding: 6px 16px;
				position: relative;
			}
		}

		li + li {
			margin-left: 32px;

			.label:before {
				content: '';
				position: absolute;
				width: 22px;
				height: 22px;
				margin-left: -11px;
				left: -16px;
				top: 0;
				background-image: url(${NextIcon});
				background-position: center;
				background-repeat: no-repeat;
			}

			&:hover .label:before {
				left: -15px;
				top: 1px;
			}
		}
	}

	@media (max-width: 600px) {
		ul li .icon {
			display: none;
		}

		height: 48px;
	}
`;

let scrollListener;
let resizeListener;

export default (props) => {
	const [index, setIndex] = useState(0);
	const ref = useRef(null);

	const update = () => {
		const app = document.querySelector(StyledApp);
		const headers = document.querySelectorAll('h2');
		const pos = app.scrollTop + window.innerHeight / 2;
		const lastHeader = [...headers].reduce(
			(p, c, i) => (c.offsetTop < pos ? i : p),
			0
		);

		if (index !== lastHeader) {
			setIndex(lastHeader);

			if (ref) {
				ref.current.scrollLeft =
					ref.current.querySelectorAll('li')[lastHeader].offsetLeft -
					ref.current.clientWidth / 2 +
					ref.current.querySelectorAll('li')[lastHeader].clientWidth / 2;
			}
		}
	};

	function scrollTo(idx) {
		const app = document.querySelector(StyledApp);
		const headers = document.querySelectorAll('h2');

		app.scrollTop = headers[idx].offsetTop - 250;
	}

	useEffect(() => {
		const app = document.querySelector(StyledApp);
		scrollListener = app.addEventListener('scroll', update);
		resizeListener = window.addEventListener('resize', update);

		update();

		return () => {
			app.removeEventListener('scroll', scrollListener);
			window.removeEventListener('resize', resizeListener);
		};
	}, [index]);

	return (
		<StyledNavigation index={index} ref={ref}>
			<ul>
				<li onClick={() => scrollTo(0)}>
					<div className="icon">
						<img src={NavigationIcon0} />
					</div>
					<div className="label">Uw taxatieverslag</div>
				</li>
				<li onClick={() => scrollTo(1)}>
					<div className="icon">
						<img src={NavigationIcon1} />
					</div>
					<div className="label">Uw WOZ-waarde</div>
				</li>
				<li onClick={() => scrollTo(2)}>
					<div className="icon">
						<img src={NavigationIcon2} />
					</div>
					<div className="label">Woningmarkt in de wijk</div>
				</li>
				<li onClick={() => scrollTo(3)}>
					<div className="icon">
						<img src={NavigationIcon3} />
					</div>
					<div className="label">Analyse overeenkomsten en verschillen</div>
				</li>
				<li onClick={() => scrollTo(4)}>
					<div className="icon">
						<img src={NavigationIcon4} />
					</div>
					<div className="label">WOZ-waarde in verhouding</div>
				</li>
				<li onClick={() => scrollTo(5)}>
					<div className="icon">
						<img src={NavigationIcon5} />
					</div>
					<div className="label">Wat is modelmatig waarderen?</div>
				</li>
				<li onClick={() => scrollTo(6)}>
					<div className="icon">
						<img src={NavigationIcon6} />
					</div>
					<div className="label">Wat is de WOZ-waarde?</div>
				</li>
				<li onClick={() => scrollTo(7)}>
					<div className="icon">
						<img src={NavigationIcon7} />
					</div>
					<div className="label">Hoe wordt mijn WOZ gebruikt?</div>
				</li>
				<li onClick={() => scrollTo(8)}>
					<div className="icon">
						<img src={NavigationIcon8} />
					</div>
					<div className="label">Vragen of opmerkingen?</div>
				</li>
			</ul>
		</StyledNavigation>
	);
};
