import React from 'react';
import styled from 'styled-components';
import IconCollapse from '../graphics/icon-collapse.svg';
import IconExpand from '../graphics/icon-expand.svg';
import IconExpandableA from '../graphics/icon-expandable-a.svg';
import IconExpandableB from '../graphics/icon-expandable-b.svg';
import IconExpandableC from '../graphics/icon-expandable-c.svg';
import MapImage from '../graphics/image-map.png';
import { Stars } from '../data/markup';

const StyledMap = styled.div`
	user-select: none;
	color: #fff;

	.expandable-items {
		display: grid;
		/* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
		grid-gap: 25px;
	}
`;

const ExpandableContent = styled.div`
	display: none;
	padding: 16px;
	.item {
		padding: 12px 0;
		border-top: 1px solid #fff;

		.key {
			font-weight: 300;
			text-transform: uppercase;
			margin-bottom: 8px;
		}

		.value {
			font-size: 18px;
		}
	}
`;

const ExpandableItem = styled.div`
	background: linear-gradient(to right, #1c93ff, #0059ff);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
	border-radius: 15px;
	/* margin: 10px; */
	display: table;
	width: 100%;
`;

const ExpandableHeader = styled.label`
	display: flex;
	appearance: none;
	border: none;
	height: 58px;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: nowrap;
	font-size: 18px;
	color: #fff;
	font-weight: 400;
	background-image: url(${IconExpand});
	background-size: 21px;
	background-repeat: no-repeat;
	background-position: calc(100% - 25px) center;

	.label {
		flex: 1;
		height: 100%;
		display: flex;
		align-items: center;
		max-width: calc(100% - 128px);
	}

	.icon {
		flex: 0 0 64px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			display: block;
		}
	}
`;

const ExpandableHeaderCheckbox = styled.input`
	appearance: none;
	width: 0;
	height: 0;
	display: block;
	border: none;
	margin: 0;
	padding: 0;

	&:checked + ${ExpandableHeader} {
		background-image: url(${IconCollapse});
	}

	&:checked ~ ${ExpandableContent} {
		display: block;
	}
`;

const StyledMapImage = styled.div`
	width: 100%;
	padding-bottom: 56.5%;
	height: 0;
	background-image: url(${MapImage});
	background-size: cover;
	border-radius: 20px;
	margin-bottom: 25px;
`;

export default () => (
	<StyledMap>
		<StyledMapImage />
		<div className="expandable-items">
			<ExpandableItem>
				<ExpandableHeaderCheckbox
					type="checkbox"
					name="expandable"
					id="expandable-header-a"
				/>
				<ExpandableHeader htmlFor="expandable-header-a">
					<div className="icon">
						<img src={IconExpandableA} />
					</div>
					<div className="label">Woningen in uw wijk Weksels Woud</div>
				</ExpandableHeader>
				<ExpandableContent>
					<div className="item">
						<div className="key">Meest voorkomende type woning</div>
						<div className="value">Vrijstaande woning</div>
					</div>
					<div className="item">
						<div className="key">Gemiddelde oppervlakte</div>
						<div className="value">182m²</div>
					</div>
					<div className="item">
						<div className="key">Gemiddelde grond bij woning</div>
						<div className="value">425m²</div>
					</div>
					<div className="item">
						<div className="key">Meest voorkomende energielabel</div>
						<div className="value">B</div>
					</div>
					<div className="item">
						<div className="key">Meest voorkomende onderhoudsstaat</div>
						<div className="value">
							<Stars opacity={1} color="#fff" rating={0.8} />
						</div>
					</div>
				</ExpandableContent>
			</ExpandableItem>
			<ExpandableItem>
				<ExpandableHeaderCheckbox
					type="checkbox"
					name="expandable"
					id="expandable-header-b"
				/>
				<ExpandableHeader htmlFor="expandable-header-b">
					<div className="icon">
						<img src={IconExpandableB} />
					</div>
					<div className="label">Woningmarkt in uw wijk Weksels Woud</div>
				</ExpandableHeader>
				<ExpandableContent>
					<div className="item">
						<div className="key">Totaal aantal woningen in uw wijk</div>
						<div className="value">314</div>
					</div>
					<div className="item">
						<div className="key">Percentage vrijstaande woningen</div>
						<div className="value">41%</div>
					</div>
					<div className="item">
						<div className="key">Percentage hoekwoningen</div>
						<div className="value">22%</div>
					</div>
					<div className="item">
						<div className="key">Percentage rijwoningen</div>
						<div className="value">17%;</div>
					</div>
					<div className="item">
						<div className="key">Aantal verkochte woningen 2019-2020</div>
						<div className="value">12</div>
					</div>
					<div className="item">
						<div className="key">
							Gemiddelde verkoopprijs vrijstaande woningen
						</div>
						<div className="value">€ 515.000,-</div>
					</div>
					<div className="item">
						<div className="key">Gemiddelde verkoopprijs alle woningen</div>
						<div className="value">€ 483.000,-</div>
					</div>
				</ExpandableContent>
			</ExpandableItem>
			<ExpandableItem>
				<ExpandableHeaderCheckbox
					type="checkbox"
					name="expandable"
					id="expandable-header-c"
				/>
				<ExpandableHeader htmlFor="expandable-header-c">
					<div className="icon">
						<img src={IconExpandableC} />
					</div>
					<div className="label">Voorzieningen in uw wijk Weksels Woud</div>
				</ExpandableHeader>
				<ExpandableContent>
					<div className="item">
						<div className="key">Aantal OV-haltes</div>
						<div className="value">5</div>
					</div>
					<div className="item">
						<div className="key">Aantal winkels</div>
						<div className="value">3</div>
					</div>
					<div className="item">
						<div className="key">Aantal horeca-vestigingen</div>
						<div className="value">2</div>
					</div>
					<div className="item">
						<div className="key">Openbaar groen op loopafstand</div>
						<div className="value">1 park</div>
					</div>
				</ExpandableContent>
			</ExpandableItem>
		</div>
	</StyledMap>
);
