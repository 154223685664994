import styled from 'styled-components';
import React from 'react';

const StyledArrow = styled.svg`
	overflow: visible;
	width: 25px;
	height: 14px;
	position: absolute;
	right: 5px;
	top: 5px;
`;

function Arrow(props) {
	const { isHigher } = props;

	if (isHigher) {
		return (
			<StyledArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 11">
				<path
					id="Path_107"
					d="M5.122,0L14.878,0C17.727,0.041 20.039,2.524 20,5.5C20.038,8.476 17.727,10.958 14.878,11L5.122,11C2.274,10.958 -0.038,8.476 0,5.5C-0.038,2.524 2.273,0.042 5.122,0Z"
					style={{ fill: 'none', stroke: 'rgb(0,188,116)', strokeWidth: '1px' }}
				/>
				<g>
					<path
						d="M13.601,8.136L13.601,2.864"
						style={{
							fill: 'none',
							stroke: 'rgb(0,188,116)',
							strokeWidth: '1px',
							strokeLinecap: 'round',
						}}
					/>
					<path
						d="M11.224,5.307L13.601,2.864L15.978,5.307"
						style={{
							fill: 'none',
							stroke: 'rgb(0,188,116)',
							strokeWidth: '1px',
							strokeLinecap: 'round',
						}}
					/>
				</g>
				<path
					d="M2.975,5.833L2.975,6.625L3.83,6.625C4.262,7.867 5.405,8.551 6.62,8.551C7.34,8.551 7.952,8.299 8.375,7.939L7.871,7.165C7.565,7.453 7.124,7.597 6.62,7.597C5.918,7.597 5.288,7.282 4.955,6.625L6.404,6.625L6.773,5.833L4.739,5.833C4.73,5.77 4.721,5.662 4.721,5.5C4.721,5.356 4.73,5.257 4.739,5.185L7.043,5.185L7.421,4.402L4.946,4.402C5.279,3.709 5.936,3.385 6.629,3.385C7.07,3.385 7.457,3.484 7.754,3.7L8.168,2.863C7.763,2.584 7.259,2.449 6.629,2.449C5.423,2.449 4.253,3.142 3.821,4.402L2.975,4.402L2.975,5.185L3.668,5.185C3.659,5.275 3.65,5.365 3.65,5.509C3.65,5.626 3.659,5.752 3.668,5.833L2.975,5.833Z"
					style={{ fill: 'rgb(0,188,116)' }}
				/>
			</StyledArrow>
		);
	}
	return (
		<StyledArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 11">
			<path
				id="Path_107"
				d="M5.122,0L14.878,0C17.727,0.041 20.039,2.524 20,5.5C20.038,8.476 17.727,10.958 14.878,11L5.122,11C2.274,10.958 -0.038,8.476 0,5.5C-0.038,2.524 2.273,0.042 5.122,0Z"
				style={{ fill: 'none', stroke: 'rgb(255,139,54)', strokeWidth: '1px' }}
			/>
			<g>
				<path
					d="M13.601,2.864L13.601,8.136"
					style={{
						fill: 'none',
						stroke: 'rgb(255,139,54)',
						strokeWidth: '1px',
						strokeLinecap: 'round',
					}}
				/>
				<path
					d="M15.978,5.693L13.601,8.136L11.224,5.693"
					style={{
						fill: 'none',
						stroke: 'rgb(255,139,54)',
						strokeWidth: '1px',
						strokeLinecap: 'round',
					}}
				/>
			</g>
			<path
				d="M2.975,5.833L2.975,6.625L3.83,6.625C4.262,7.867 5.405,8.551 6.62,8.551C7.34,8.551 7.952,8.299 8.375,7.939L7.871,7.165C7.565,7.453 7.124,7.597 6.62,7.597C5.918,7.597 5.288,7.282 4.955,6.625L6.404,6.625L6.773,5.833L4.739,5.833C4.73,5.77 4.721,5.662 4.721,5.5C4.721,5.356 4.73,5.257 4.739,5.185L7.043,5.185L7.421,4.402L4.946,4.402C5.279,3.709 5.936,3.385 6.629,3.385C7.07,3.385 7.457,3.484 7.754,3.7L8.168,2.863C7.763,2.584 7.259,2.449 6.629,2.449C5.423,2.449 4.253,3.142 3.821,4.402L2.975,4.402L2.975,5.185L3.668,5.185C3.659,5.275 3.65,5.365 3.65,5.509C3.65,5.626 3.659,5.752 3.668,5.833L2.975,5.833Z"
				style={{ fill: 'rgb(255,139,54)' }}
			/>
		</StyledArrow>
	);
}

const types = ['Rijwoning', 'Hoekwoning', 'Vrijstaand'];
const neighborhoods = [
	'Hazenkamp',
	'Weksels Woud',
	'Waterkwartier',
	'Koningsdaal',
];

const comparison = {
	'location.neighborhood': (a, b) =>
		neighborhoods.indexOf(a) - neighborhoods.indexOf(b),
	'characteristics.type': (a, b) => types.indexOf(a) - types.indexOf(b),
	'characteristics.buildingSize': (a, b) => a - b,
	'characteristics.landSize': (a, b) => a - b,
	'characteristics.annex': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.size - b.size,
	'characteristics.detachedStorage': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.size - b.size,
	'characteristics.annexStorage': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.size - b.size,
	'characteristics.awning': (a, b) => (a == b ? 0 : a == null ? -1 : 1),
	'characteristics.garage': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.size - b.size,
	'characteristics.dormers': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.count - b.count,
	'sustainability.energyLabel': (a, b) => (a == b ? 0 : a < b),
	'sustainability.solarPanels': (a, b) =>
		a == b ? 0 : a == null ? -1 : b == null ? 1 : a.count - b.count,
	'quality.maintenanceRating': (a, b) => a - b,
	'quality.qualityRating': (a, b) => a - b,
	'quality.serviceRating': (a, b) => a - b,
	'worth.value': (a, b) => 0,
};

export default (path, value, baseline) => {
	if (!comparison.hasOwnProperty(path)) return '';

	const v = comparison[path](value, baseline);

	if (v === 0) return '';
	return <Arrow key="arrow" isHigher={v > 0} />;
};
