import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
html, body {
	height: 100%;
}
	a, a:hover, a:visited, a:active {
		color: #00A365;
		text-decoration: none;
	}

	p + p {
		margin-top: 1em;
	}
`;
