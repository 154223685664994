import styled from 'styled-components';
import React from 'react';

const StyledStars = styled.svg`
	path {
		fill: none;
		stroke: ${(props) => props.color};
		stroke-width: 1;
		stroke-linecap: round;
	}

	path.filled {
		fill: ${(props) => props.color};
	}

	opacity: ${(props) => props.opacity};

	overflow: visible;
	width: 101px;
	height: 16px;
	flex-basis: 100%;
`;

export function Stars(props) {
	const { rating, color, opacity } = props;

	return (
		<StyledStars
			color={color || '#3c3c3b'}
			opacity={opacity || 0.6}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 17 16"
		>
			{[0, 1, 2, 3, 4].map((i) => (
				<path
					d="M8.316,13.552L3.693,15.95C3.634,15.983 3.568,16 3.5,16C3.35,16 3.212,15.915 3.142,15.78C3.101,15.702 3.086,15.612 3.099,15.524L3.98,10.448C4.003,10.317 3.959,10.182 3.863,10.09L0.123,6.495C-0.033,6.344 -0.042,6.09 0.104,5.929C0.169,5.859 0.256,5.815 0.349,5.806L5.515,5.065C5.646,5.046 5.759,4.964 5.819,4.844L8.132,0.226C8.203,0.087 8.345,0 8.499,0C8.567,0 8.633,0.017 8.693,0.049C8.767,0.089 8.828,0.151 8.867,0.226L11.179,4.844C11.239,4.964 11.352,5.046 11.482,5.065L16.648,5.806C16.847,5.826 17,5.999 17,6.203C17,6.217 16.999,6.231 16.998,6.245C16.988,6.34 16.945,6.429 16.876,6.495L13.137,10.09C13.04,10.182 12.996,10.317 13.019,10.448L13.902,15.524C13.905,15.544 13.907,15.565 13.907,15.585C13.907,15.79 13.758,15.965 13.559,15.994C13.473,16.007 13.385,15.991 13.308,15.949L8.684,13.551C8.567,13.495 8.432,13.495 8.316,13.552Z"
					key={i}
					transform={`translate(${(i - 2) * (17 + 4)}, 0)`}
					className={(i + 1) / 5.0 <= rating ? 'filled' : 'unfilled'}
				/>
			))}
		</StyledStars>
	);
}

const ratings = [
	'Slecht',
	'Matig',
	'Kan beter',
	'Gemiddeld',
	'Goed',
	'Uitstekend',
];

const markup = {
	'location.neighborhood': (c) => [
		<div key="a" style={{ flexBasis: '100%', textAlign: 'center' }}>
			{c}
		</div>,
		<div key="b">Juinen</div>,
	],
	'characteristics.buildingSize': (c) => `${c}m²`,
	'characteristics.landSize': (c) => `${c}m²`,
	'characteristics.annex.size': (c) => `${c}m²`,
	'characteristics.detachedStorage.size': (c) => `${c}m²`,
	'characteristics.annexStorage.size': (c) => `${c}m²`,
	'characteristics.garage.size': (c) => `${c}m²`,
	'sustainability.solarPanels.count': (c) => `${c} stuks`,
	'sale.price': (c) => `€ ${c.toLocaleString('nl-NL')},-`,
	'worth.value': (c) => `€ ${c.toLocaleString('nl-NL')},-`,
	'quality.qualityRating': (c) => [
		<Stars rating={c} key="stars" />,
		ratings[c * 5],
	],
	'quality.serviceRating': (c) => [
		<Stars rating={c} key="stars" />,
		ratings[c * 5],
	],
	'quality.maintenanceRating': (c) => [
		<Stars rating={c} key="stars" />,
		ratings[c * 5],
	],
};

export default (path, content) => {
	if (markup.hasOwnProperty(path)) return markup[path](content);
	return content;
};
